.flex-row {
  display: flex;
}

#root {
  position: relative;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dx-datagrid-headers {
  background: linear-gradient(183.41deg, #67c3f3 -8.57%, #5a98f2 82.96%);
  color: #fff;
  font-weight: bold;
  height: 50px;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 25%);
  border: 0;
  border-radius: 5px;
  line-height: 25px;
}

.dx-header-row td:first-child {
  padding-left: 20px !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border: none;
}

.dx-datagrid-header-panel {
  border: none;
}

.dx-datagrid-table-fixed {
  border: 0;
}

.dx-datagrid {
  font-size: 16px;
}

.dx-datagrid .dx-row > td {
  padding: 12px;
}

svg {
  pointer-events: none;
}

/* The beginning of a universal style library*/

/* MUI Switch - Global */
.MuiSwitch-thumb :not(.Mui-disabled) {
  color: #195ffb;
  /* background-color: red; */
}

/* MUI Filled Input - Focused */
.MuiInputBase-root.MuiFilledInput-root:hover,
.MuiInputBase-root.MuiFilledInput-root.Mui-focused {
  background-color: #eff5f9;
}

/* MUI Buttons - Global */
/* .MuiButton-root{
  padding: 15px;
  font-style: normal;
  font-size: 14px;
  min-width: 100px ;
  width: auto ;
} */

/* MUI Buttons - Contained */
/* .MuiButton-root.MuiButton-contained {
  background-color: #195ffb;
  text-transform: none;
  border-radius: 5;
  font-weight: 600;
  box-shadow:none;
  color: white;
  padding: 15px;
}

.MuiButton-root.MuiButton-contained:hover, .MuiButton-root.MuiButton-contained:focus {
  background-color: #195ffb;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
} */

.MuiButton-root.MuiButton-contained:active {
  /* Transition for click */
}

/* .MuiButton-root.MuiButton-contained.Mui-disabled {
  background: rgba(31, 31, 31, 0.12);
} */

/* MUI Buttons - Outlined */

/* .MuiButton-root.MuiButton-outlined{
  border: 1px solid #79747E;
  border-radius: 5;
  color:  #195ffb;
  text-transform: none;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 15px;
} */

/* .MuiButton-root.MuiButton-outlined:hover,.MuiButton-root.MuiButton-outlined:focus{
  background-color: rgba(25, 95, 251, .08);
} */

.MuiButton-root.MuiButton-outlined:active {
  /* Transition for click */
}

/* .MuiButton-root.MuiButton-outlined.Mui-disabled {
  border: 1px solid rgba(31, 31, 31, 0.12);
  color: #1C1B1F;
  opacity: 0.38;
} */

/* MUI Buttons - Text */
/* .MuiButton-root.MuiButton-text{
  color: #195FFB;
  font-weight: 600;
  font-size: 14px;
  text-transform: none;
} */

/* .MuiButton-root.MuiButton-text:hover, .MuiButton-root.MuiButton-text:focus{
  background: rgba(25, 95, 251, 0.08);
  border-radius: 100px;
} */
/* 
.MuiButton-root.MuiButton-text.Mui-disabled{
  color: #1C1B1F;
  opacity: 0.38;
} */

@media screen and (max-width: 800px) {
  /* .MuiButton-root{
    width: 100% !important;
  } */
}
/* .MuiButtonBase-root.MuiRadio-root.Mui-checked, .MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color:black
} */

.hidden {
  display: none;
}

/* ///// Custom Scroll style /////*/

/* Vertical scrollbar */

::-webkit-scrollbar {
  width: 12px;
}

/* Horizontal scrollbar */

::-webkit-scrollbar {
  height: 12px;
}

/* Both Vertical and Horizontal scrollbars*/

::-webkit-scrollbar-track:horizontal,
::-webkit-scrollbar-track:vertical {
  background: rgba(249, 249, 249, 1);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:horizontal,
::-webkit-scrollbar-thumb:vertical {
  background: rgba(193, 193, 193, 1);
  border: 4px solid transparent;
  border-radius: 100px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:horizontal:hover,
::-webkit-scrollbar-thumb:vertical:hover {
  background: rgba(178, 178, 178, 1);
  border: 4px solid transparent;
  border-radius: 100px;
  background-clip: content-box;
}
