.work-link {
    width: 131px;
    height: 36px;    
    border-top-left-radius: 5px;
    border-top-right-radius:5px;
    margin-left:2px;
    /* I need the right color */
    background: #106695;
    text-align:center;
    justify-content: center;
    align-items: center;
    line-height: 36px;
    display:inline-block;
    /* transform: rotate(180deg); */
}

a.work-link {
    color:#FFF;
}

.work-link.selected {
    font-weight:bold;
    background: #1B2F5A;
}

.work-link a:hover {
    font-weight:bold;
}

