/* .welcome-div {
    text-align: center;
    font-weight: bold;
    padding-top: 15px;
    font-size: 20px;
    letter-spacing: -.5px;
} */

/* .logo-img {
    width: 166px;
    height: 61px;
    display: block;
    margin: auto;
    margin-bottom: 64px;
} */

.email-confirmation-alert-container {
    padding-left: 25px;
    padding-right: 25px;
}

.email-gray-box-container {
    display:table;
    width:100%;
    height:100vh;
}
.email-gray-box-inner-container {
    display: table-cell;
    vertical-align: middle;
}

.email-thumbs-up-container {
    width: 65%;
    height: 80vh;
    margin: auto;
    background-color: #F0F0F04D;
    border-radius: 8px;
    display: table;
}

.email-inner-thumbs-up-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.email-header-container {
    margin-top: 30px;
    margin-bottom: 28px;
}


.thumbsUp-img {
    margin: auto;
    display: block;
    width:38%;
}

.MuiPaper-root.email-confirmation-password-alert {
    border-radius: 12px;
    font-size: 14px;
    margin-bottom: 222px;
}

.MuiTypography-root.email-confirmation-password-title {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: .15px;
    line-height: 20px;
    padding-top: 3px;
}