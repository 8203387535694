div.rate-calculator-body {
  /* overflow-x:initial; */
  height: calc(100vh - 55px);
  overflow-y: auto;
  overflow-x: hidden;
}
div.rate-calculator-body * {
  font-family: "Archivo", sans-serif;
  /* font-family:'Roboto', sans-serif ; */
}
div.rate-calculator-body b {
  font-weight: 900;
}

/* div.user-input-container {
  padding: 0px 15px;
} */

div.rate-calculator-container {
  margin: 20px;
  /* margin-left: 6%; 
  margin-right: 6%; */
}

.recommendationText {
  text-align: center;
  padding-top: 10px;
}

div.calculator-topbar {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  margin-bottom: 15px;
}
.calculator-header {
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;
  width: 70%;
  margin-bottom: 15px;
}
div.header-info {
  font-weight: 300;
  font-size: 1 rem;
  letter-spacing: 0.15px;
  width: 100%;
}
img.calculator-logo-img {
  position: absolute;
  top: -5px;
  right: 0%;
}

/* .MuiFormHelperText-root{
    text-align: center !important;
} */

div.enrollment-countdown-date {
  padding-top: 0px;
  padding-left: 0px;
  margin-bottom: 20px;
}
div.enrollment-countdown-date > h6 {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.65px;
}
div.enrollment-countdown-date > span {
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.65px;
  color: #195ffb;
}
div.enrollment-countdown {
  border-radius: 100px;
  min-width: 124px;
  height: 82px;
  background-color: #F9F9F9;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: auto;
  margin-right: 0;

}

.std-helptext,
.ltd-helptext,
.ladd-helptext {
  margin-top: 16px;
  font-weight: 300;
  font-size: 10px;
  letter-spacing: 0.15px;

}
.std-helptext > span,
.ltd-helptext > span,
.ladd-helptext > span {
  font-size: 16px;
 
}

.ltd-explanation,
.ltd-offsets {
  margin-top: 15px;
  font-weight: 300;
  font-size: 10px;
  letter-spacing: 0.15px;
}
.ladd-explanation,
.ladd-offsets {
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.15px;
}

.monthly-cost {
  /* text-align: right; */
  /* position: absolute; */
  /* right: 8%; */
  /* top: 25%; */
  /* bottom: 5%; */
  font-size: 16px;
}
.option-container {
  padding: 0px 8%;
  position: relative;
  margin-bottom: 20px;
}

/* .MuiInputBase-input.centered-input, .MuiInputBase-input#DOBinput {
    text-align: center;
} */

.Mui-disabled.MuiButton-root.calculate-btn {
  background-color: #195ffb;
  color: white;
  opacity: 0.2;
}

.MuiButton-root.calculate-btn {
  border-radius: 100px;
}

.MuiButton-root.survey-btn {
  border-radius: 100px;
}

div.sticky-bottom-total {
  width: 100%;
  padding: 10px;
  display: block;
  position: sticky;
  position: -webkit-sticky;
  bottom: -1px;
  color: #195ffb;
  text-align: center;
  font-size: 20px;
}

::-webkit-calendar-picker-indicator {
  /* color:red;
    background-color: red; */
  display: none;
}

@media only screen and (min-width: 425px) {
  .mobile-picker {
    display: none;
    visibility: hidden;
  }
  .mobile-calc-enroll-btn {
    display: none;
    visibility: hidden;
  }
  /* .desktop-picker {
        display: initial;
        visibility: initial;
    } */
}
@media only screen and (max-width: 425px) {
  /* .mobile-picker {
        display: initial;
        visibility: initial;
    } */
  .desktop-picker {
    display: none;
    visibility: hidden;
  }
  .desktop-calc-enroll-btn {
    display: none;
    visibility: hidden;
  }
}

@media only screen and (min-width: 550px) {
  .calculator-topbar {
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 425px) {
  .mobile-md-benefit-schedule {
    display: none;
    visibility: hidden;
  }
  .monthly-cost {
    top: 5%;
  }
  .option-container {
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 425px) {
  .mobile-sm-benefit-schedule {
    display: none;
    visibility: hidden;
  }
  div.rate-calculator-body {
    height: 100vh;
  }
}
@media only screen and (min-width: 1000px) {
  .desktop-width-limiter {
    max-width: 1000px;
    display: block;
    margin: auto;
  }
}
