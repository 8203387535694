.sidebar-back {
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    padding-left:40px;
    padding-top:20px;
}

.sidebar-back a {
    font-size: 20px;
    font-weight: 700;
    color: #67C3F3;
}