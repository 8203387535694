#topbar-filter-icon {
    color: #7D7987;
    margin:10px;
    margin-right:100px;
    
}
#topbar-searchfield {
    width:300px;
}

