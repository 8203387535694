.plan-explanation-innerHTML li {
  line-height: 18px;
  margin: 5px 0px;
}

.plan-explanation-innerHTML ul {
  padding-left: 1.3rem;
}

/* Hospital Plan High and Low Tables */
.explanationOfBenefitsHospital-innerHTML {
  width: 100%;
}
.explanationOfBenefitsHospital-innerHTML table {
  width: 100%;
  margin: 0px;
  font-family: Archivo;
}

.explanationOfBenefitsHospital-innerHTML tr {
  padding-bottom: 10px;
}
.explanationOfBenefitsHospital-innerHTML td {
  text-align: right;
  vertical-align: top;
}

/* Left-align the first column */
.explanationOfBenefitsHospital-innerHTML td:first-child {
  text-align: left;
}

/* Right-align the second column */
.explanationOfBenefitsHospital-innerHTML td:nth-child(2) {
  text-align: right;
  padding-right: 10px;
}

.explanationOfBenefitsHospital-innerHTML span.hospitalTableHeavy {
  font-weight: 600;
}
.explanationOfBenefitsHospital-innerHTML span.hospitalTableLight,
span.hosptialTableLight {
  font-size: 0.7rem !important;
}
