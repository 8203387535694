#sidebar-profile {
    height: 95px;
    padding: 15px;
    border-bottom: 0.5px solid #7D7987;
}

.sidebar-profile-name {
    font-weight: bold;
    position:relative;
    text-align:right;
    width:100%;
    padding-left:15px;
}

.sidebar-profile-image {
    border-radius:50%;
    -webkit-box-shadow: rgba(0,0,0,0.8) 0px 0 10px;
    -moz-box-shadow: rgba(0,0,0,0.8) 0 0 10px;
    box-shadow: rgba(0,0,0,0.8) 0 0 10px;
}

/* .sidebar-profile-text {
    color:#1B2F5A;
    font-weight:bold;
    font-size:16px;
} */