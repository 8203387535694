.link-button {
    background-color:#67C3F3;
    width: 230px;
    border-radius:5px;
    color:#FFF;
    margin: auto;
    margin-top:10px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    height: 54px;
    font-weight:bold;
    line-height:54px;
    display:block;
    text-align: center;
}

.link-button:hover {
    background-color:#1B2F5A;
    color:#FFF;
    /* cursor: pointer; */
}

/* .link-button a {
    display:inline-block;
    color:#FFF;
    height: 100%;
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:16px;
    font-weight:bold;
    line-height:54px;
}  */

/* .link-button a:hover {
    background-color:#1B2F5A;
} */



/* .link-button button {
    display:inline-block;
    color:#FFF;
    height: 100%;
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:16px;
    font-weight:bold;
    line-height:40px;
    background-color:#67C3F3;
    border-radius:5px;
    border:none;
} */

/* .link-button button:hover {
    background-color:#1B2F5A;
} */


/* .link-button:hover {
    background-color:#1B2F5A;
} */

.image-button {
    position: absolute;
    left: 30px;
}