.settings-team-employee-card-item-container {
    margin: 17px 0px 0px 0px;
}

.settings-team-employee-card-column-container {
    column-gap: 40px;
    display: grid;
    grid-template-columns: 50% 50%;
}

.settings-team-employee-card-button-container {
    position: relative !important;
    min-height: 150px !important;
}