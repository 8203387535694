.topbar-link-button {
    background-color: #67C3F3;
    width: auto;
    height:42px;
    border-radius: 5px;
    color: #FFF;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    /*padding-bottom: 9px;
    padding-top: 9px;*/
    text-align: center;
    margin:auto;
    /*padding:0px 18px;*/
    margin-right:10px;
    margin-left:10px;
}
.topbar-link-button:last-child {
    margin-right:0px;
}
.topbar-link-button > span {
    vertical-align:middle;
    margin:auto;
    margin-left:10px;
    font-size:15px;
    letter-spacing:.46px;
}
.topbar-link-button > svg {
    margin-top:3px;

}

.topbar-link-button:hover {
    background-color:#1B2F5A;
    color:#FFF;
    /* cursor: pointer; */
}

 .topbar-link-button a {
    /*display:inline-block;
    color:#FFF;
    height: 100%;
    width: 100%;
    display:flex;*/
    /*justify-content: center;
    align-items: center;
    font-size:16px;
    font-weight:bold;
    line-height:54px;*/
}  

/* .link-button a:hover {
    background-color:#1B2F5A;
} */



/* .link-button button {
    display:inline-block;
    color:#FFF;
    height: 100%;
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:16px;
    font-weight:bold;
    line-height:40px;
    background-color:#67C3F3;
    border-radius:5px;
    border:none;
} */

/* .link-button button:hover {
    background-color:#1B2F5A;
} */


/* .link-button:hover {
    background-color:#1B2F5A;
} */

/*.image-button {
    position: absolute;
    left: 30px;
}*/