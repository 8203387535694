.reset-password-req > h5 {
    text-align:center;
    font-weight:700;
    font-size:18px;
    letter-spacing:.2px;
    margin-top:74px;
    margin-bottom:30px;
}

.reset-password-req > ul {
    padding-left:20px;
    width:324px;
    font-size:14px;
    letter-spacing:.2px;
    margin-bottom:150px;
}

.MuiFormControl-root.reset-password-input{
    margin-bottom:10px;
}
.MuiButton-root.reset-password-btn {
    display:block;
    margin:auto;
    margin-top:10px;
    width:178px;
    height:42px;
    letter-spacing:.46px;
}

.aliens-img-reset {
    display:block;
    margin:auto;
    width:185px;
    height:202px;
    margin-bottom:22px;
}

.reset-alert-container {
    padding-left: 25px;
    padding-right: 25px;
}

.MuiPaper-root.reset-password-alert {
    border-radius: 12px;
    font-size: 14px;
    margin-bottom: 175px;
}

.MuiTypography-root.reset-password-title {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: .15px;
    line-height: 20px;
    padding-top:3px;
}