.notificationBodyHtml div ul{
    list-style: none;
    padding: 0px;
    margin: 0;
    text-align: left;
    
}
.notificationBodyHtml div ul li{
    padding: 0px;
    margin: 0;
    text-align: left;
    line-height: 18px;

    
}