
html {
    max-width: 100%;
    overflow-x: hidden;
    
}

body {
    margin: 0;
    max-width: 100%;
    overflow-x: hidden;
    font-family: open-sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Archivo', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  /* text-decoration: none !important; */
}