.sidebar {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    /* Disable */
    
    background: #F2F2F2;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    min-height: 300px;
    width: 250px;
    border-radius: 5px;
    margin-bottom:20px;
    /* display:inline-block; */
    /* float:left; */
    margin-right:15px; /* this pushes stuff to the right of the sidebar */
}