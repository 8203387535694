.agent-comment {
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    background: linear-gradient(183.41deg, #67C3F3 -8.57%, #5A98F2 82.96%);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    text-align: left;
    color: #FFF;
    font-weight: 600;
    font-size: 16px;
    padding:0px;
    padding-left: 15px;
}


.add-dependent {
    width: 100%;
    height: 50px;
    margin-top: 22px;
    color: #1B2F5A;
    line-height: 40px;
    font-weight: 700;
    font-size: 16px;
    vertical-align:middle;
    display:flex;
    justify-content:flex-end;
    padding-right:10px;
}
.add-dependent > img {
    margin-right:9px;
    padding-top:5px;
}

.working-status {
    background-color: #d9f0fc;
    margin-top:29px;
    margin-left:10px;
    margin-right:0px;
    padding-top:10px;
    line-height:83px;
}
.working-status > div > label {
    padding-top:15px;
    margin-right:-10px;
}
.working-status > div > div {
    padding-top:10px;
    padding-bottom:10px;
    /*padding-right:50px;*/
    padding-left:0px
}

.spouse-access {
    background-color: #f2f2f2;
    margin-left:10px
}
.spouse-access > div > label {
    margin-left:45px;
    padding-bottom:0px;
}
.spouse-access > div > div.col-sm-6 {
    padding-left:7px;
}
.spouse-access > div > div > label > input {
    width: 45px;
    height: 25px;
    vertical-align:middle;
    background-color: white;
    outline-color:lightgray;
}
.spouse-access > div > div > label {
    font-weight:normal;
    padding-right:30px;
    padding-bottom:0px;
}

/*INPUTS*/

input.phone-input {
    
}
input.address-check {
    width: 20px;
    height: 20px;
    margin-top: 18px;
    vertical-align:middle;
    background-color: white;
    outline-color:lightgray;
}
input.contact-method {
    width: 25px;
    height: 25px;
    vertical-align:middle;
    margin-right:10px;
    background-color: white;
    outline-color:lightgray;
}
input.gender-choice {
    width: 25px;
    height: 25px;
    margin-right:10px;
    vertical-align: middle;
    background-color: white
}
input.spouse-email {

}


/*LABELS*/

label:first-of-type {
    /*margin-right:10px*/
}
label.thin-label {
    margin-top:10px;
    font-weight:normal;
    text-align:center
}
label.contact-label {
    font-weight:normal;
    text-align:left;
    padding-bottom:0px;
}
label.address-label {

}
label.gender-label {
    padding:0px;
}
label.gender-radio-label {
    padding: 0px;
    font-weight: normal;
    text-align: center;
    /*margin-bottom:10px;*/
}

/*BUTTONS*/

button.confirm-email{
font-weight:600;
height:60px;
text-align:center;
background-color:whitesmoke;
border-color:whitesmoke;
box-shadow:3px 3px 1px 0px lightgray;
}

/*DIV*/

div.union-employer {
    padding-top:0px;
}

/*FORM-GROUP STYLES FROM MAIN STYLESHEET*/

.spouse-break {
    padding-left:10px;
    padding-top:20px;
}
.spouse-break > hr {
    margin: 0px;
    margin-top: 10px;
    color: #1B2F5A;
}

.spouse-break label {
    color: #1B2F5A;
    line-height: 40px;
    font-weight: 700;
    font-size: 18px;
}

/*TURN ADRESS RADIO INTO BOXES*/
.address-check {
    padding: 0.5em;
    -webkit-appearance: none;
    outline: 0.1em solid black;
    outline-offset: 0.1em;
}

.address-check:checked {
    display: inline-block;
    background-color: #5da1f2;
}