.back-btn-div {
    margin-top: 13px;
    color: #536DFE;
    align-self:center;
}

.back-btn-div > span {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: .46px;
}

.home-btn-div {
    margin-top: 13px;
    color: #536DFE;
    flex-flow: row-reverse;
}