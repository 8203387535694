/* Member Profile L-side Info scrollbar */
.scrollable-content::-webkit-scrollbar {
    width: 10px;
  }
  .scrollable-content::-webkit-scrollbar-track {
    background: rgba(249, 249, 249, 1);
    border-radius: 100px;
  }
  
  .scrollable-content::-webkit-scrollbar-thumb {
    /* background: rgba(193, 193, 193, 1); */
    border: 4px solid transparent;
    border-radius: 100px;
    background-clip: content-box;
  }
  
  .scrollable-content::-webkit-scrollbar-thumb:hover {
    background: rgba(178, 178, 178, 1);
    border: 4px solid transparent;
    border-radius: 100px;
    background-clip: content-box;
  }