.topbar-back {
    color: #67C3F3;
    padding-top:10px;
    margin-right:25px
}
.topbar-back > svg {
    margin-bottom:6px;
    margin-right:20px;
}
.topbar-back > a {
    font-size: 16px;
    font-weight: 700;
    color: #67C3F3;
}